// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@popperjs/core")
require("bootstrap/dist/css/bootstrap.min.css")

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "./style.scss"
import "bootstrap-icons/font/bootstrap-icons.css"
import * as jwt from 'jsonwebtoken'
import * as livechat from "@livechat/agent-app-sdk"
import ClipboardJS from 'clipboard'
import { Tooltip } from "bootstrap"

window.livechat = livechat
window.ClipboardJS = ClipboardJS
window.jwt = jwt
Rails.start()
Turbolinks.start()
ActiveStorage.start()

$( document ).ready(function() {
  $('#google-login').click(function(){
     $("#google-login-message").removeClass("d-none")
  });

 if ( window.location == window.parent.location ){
     $('#google-login').removeAttr('target');
      $("#google-login-message").remove()
    }
});

document.addEventListener("turbolinks:load", () => {
  //This is from the Bootstrap 5 docs
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  tooltipTriggerList.map(function(tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl)
  })
})